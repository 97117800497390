import Image from "next/image";
import styles from './error.module.css';
import { useRouter } from 'next/router';

export function Custom404() {
    const router = useRouter();
    const { project } = router.query;
    const handleRedirect = () => {
        if (project) {
            // Redirect to /home with the project parameter in the same tab
            window.location.href = `/home?project=${project}`;
        } else {
            console.error('Project parameter is missing');
        }

      };
    return (
        <div className="container">
            <div className={styles.error404Sec}>
                <h1 id="errorPage" className={styles.mainHeading}>ERROR 404</h1>
                <div className={styles.imgBx}>
                    <div className={styles.subHeading}>page not found</div>
                    <Image
                        className="img-responsive mb46"
                        width={415}
                        height={68}
                        src="/assets/images/Error404_2.svg"
                        alt="" />
                    <Image
                        className="img-responsive mb46"
                        width={498}
                        height={202}
                        src="/assets/images/Error404_1.svg"
                        alt=""
                    />
                </div>
            <button onClick={handleRedirect} className={styles.homeButton}>Back to Dashboard</button>
            </div>
        </div>
    );
}